<template>
  <div>
    <v-simple-table>
      <template v-slot:default>
        <thead>
          <tr>
            <td>Elements</td>
            <td>Symbols</td>
          </tr>
          <tr v-for="(question, index) in questions" :key="question">
            <td style="padding-bottom: 2em">{{ question }}</td>
            <td>
              <template :content="options.text">
                <v-select
                  v-model="inputs[`input${index + 1}`]"
                  :items="options"
                  class="symbols"
                  filled
                >
                  <template #selection="{item}">
                    <chemical-latex class="no-text-transform" :content="item.text" />
                  </template>
                  <template #item="{item}">
                    <chemical-latex class="no-text-transform" :content="item.text" />
                  </template>
                </v-select>
              </template>
            </td>
          </tr>
        </thead>
      </template>
    </v-simple-table>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import ChemicalLatex from '@/tasks/components/displayers/ChemicalLatex.vue';

export default {
  name: 'MatchSymbolToElement',
  components: {ChemicalLatex},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        input1: null,
        input2: null,
        input3: null,
        input4: null,
        input5: null,
        input6: null,
        input7: null,
        input8: null,
        input9: null,
        input10: null,
        input11: null,
        input12: null,
        input13: null,
        input14: null,
        input15: null,
      },
      options: [
        {text: 'Ag', value: 'Ag'},
        {text: 'Au', value: 'Au'},
        {text: 'Co', value: 'Co'},
        {text: 'Cu', value: 'Cu'},
        {text: 'Fe', value: 'Fe'},
        {text: 'Fm', value: 'Fm'},
        {text: 'Hg', value: 'Hg'},
        {text: 'Ir', value: 'Ir'},
        {text: 'K', value: 'K'},
        {text: 'Na', value: 'Na'},
        {text: 'P', value: 'P'},
        {text: 'Pb', value: 'Pb'},
        {text: 'S', value: 'S'},
        {text: 'Sn', value: 'Sn'},
        {text: 'Si', value: 'Si'},
        {text: 'Ti', value: 'Ti'},
        {text: 'W', value: 'W'},
      ],
      questions: [
        'Copper',
        'Sulfur',
        'Fermium',
        'Silver',
        'Lead',
        'Cobalt',
        'Mercury',
        'Tin',
        'Potassium',
        'Iron',
        'Silicon',
        'Gold',
        'Phosphorus',
        'Sodium',
        'Tungsten',
      ],
    };
  },
};
</script>
<style>
.symbols {
  width: 150px;
  overflow: hidden;
}
</style>
